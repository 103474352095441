import URI from 'urijs';
import { getRedirectUrl, navigateToPage } from './ui-util';


export function forwardToLoginPage({ includeCurUriAsRedirect = true, replaceHistory = true } = {}) {
    navigateToPage(createLoginLink(), { includeCurUriAsRedirect, replaceHistory });
}

export function navigateToHome({ includeCurUriAsRedirect = false, replaceHistory = false } = {}) {
    navigateToPage(createHomepageLink(), { includeCurUriAsRedirect, replaceHistory });
}

export function navigateToSelectCompanyPage({ includeCurUriAsRedirect = false, replaceHistory = false } = {}) {
    navigateToPage(createSelectCompanyLink(), { includeCurUriAsRedirect, replaceHistory });
}

export function navigateToRedirectedUrl({ fallbackUri, replaceHistory = true } = {}) {
    const target = getRedirectUrl({ defaultPath: fallbackUri });
    navigateToPage(target, { replaceHistory });
}



export function createLoginLink() {
    const targetUri = new URI('./login/').absoluteTo(getBaseUri());
    return targetUri.toString();
}

export function createSignUpLink() {
    const targetUri = new URI('./sign-up/').absoluteTo(getBaseUri());
    return targetUri.toString();
}

export function createForgotPasswordLink() {
    const targetUri = new URI('./forgot-password/').absoluteTo(getBaseUri());
    return targetUri.toString();
}




export function createHomepageLink() {
    const targetUri = new URI('./').absoluteTo(getBaseUri());
    return targetUri.toString();
}

export function createSelectCompanyLink() {
    const targetUri = new URI('./select-company/').absoluteTo(getBaseUri());
    return targetUri.toString();
}

export function createCompanyPageLink(companyId) {
    const targetUri = new URI('./companies/').absoluteTo(getBaseUri());
    targetUri.setQuery({
        cid: companyId,
    });
    return targetUri.toString();
}

export function createProjectPageLink(companyId, projectId) {
    const targetUri = new URI('./projects/').absoluteTo(getBaseUri());
    targetUri.setQuery({
        cid: companyId,
        pid: projectId,
    });
    return targetUri.toString();
}

export function createPanoViewerLink(companyId, projectId, { deliverableId, sceneId, renderId } = {}) {
    const targetUri = new URI('./viewer/').absoluteTo(getBaseUri());
    // TODO this needs to be kept in sync with ui-url-manager, should move everything together at some point
    targetUri.setQuery({
        cid: companyId,
        pid: projectId,
        did: deliverableId,
        sid: sceneId,
        renin: renderId,
    });
    return targetUri.toString();
}

export function createFlatViewerLink(companyId, projectId, { deliverableId, sceneId, renderId } = {}) {
    const targetUri = new URI('./rendering-viewer/').absoluteTo(getBaseUri());
    // TODO this needs to be kept in sync with ui-url-manager, should move everything together at some point
    targetUri.setQuery({
        cid: companyId,
        pid: projectId,
        did: deliverableId,
        sid: sceneId,
        renin: renderId,
    });
    return targetUri.toString();
}

export function createFloorplan3DViewerLink(companyId, projectId, { deliverableId, sceneId, renderId } = {}) {
    const targetUri = new URI('./3d-floorplan-viewer/').absoluteTo(getBaseUri());
    // TODO this needs to be kept in sync with ui-url-manager, should move everything together at some point
    targetUri.setQuery({
        cid: companyId,
        pid: projectId,
        did: deliverableId,
        sid: sceneId,
        renin: renderId,
    });
    return targetUri.toString();
}


function getBaseUri() {
    let baseUrl;
    if (window.location.href.includes(process.env.ABVR_LOCAL_URL_BASE)) {
        baseUrl = process.env.ABVR_LOCAL_URL_BASE;
    } else if (window.location.href.includes(process.env.ABVR_ONLINE_URL_BASE)) {
        baseUrl = process.env.ABVR_ONLINE_URL_BASE;
    } else {
        throw new Error(`Unable to find base url from current URL '${window.location.href}'`);
    }

    return new URI(baseUrl);
}