
import '../../common/main-ui/commonImports'; // import common css and js we know we need first
import $ from 'jquery';
import * as uiUtil from '../../common/main-ui/ui-util';
import { asyncTimeout, isDevelopment } from '../../common/data-manager/lib/util';
import { initAnalytics, sendVirtualPageView } from '../../common/main-ui/analytics/analytics';
import { curUser, getRedirectPageFromAuthState, handleAuthStateError, isUserSignedIn, logAuthData, signUserInWithAuthCode, } from '../../common/data-manager/users/auth';
import URI from 'urijs';
import { setFullstoryIdentity } from '../../common/main-ui/analytics/fullstory';
import DataState from '../../common/main-ui/DataState';

import '../../common/main-ui/style.css';
import { showAlert } from '../../common/main-ui/ui-alert';
import { createSelectCompanyLink, forwardToLoginPage, navigateToRedirectedUrl } from '../../common/main-ui/page-url-manager';

initAnalytics();
uiUtil.logAppData();

/****************************************************/
/****************** GLOBAL OBJECTS ******************/
/****************************************************/

const curData = new DataState();
window.curData = curData;
const startUrl = new URI();
const { code: authCode, error: authError, state: authState } = startUrl.search(true); // auth code from cognito or other


/****************************************************************/
/****************** HOME PAGE SPECIFIC METHODS ******************/
/****************************************************************/


$(async () => {
	sendVirtualPageView();

	if (isDevelopment()) { // If we're in development, setup our viewer so we know it (yellow top bar)
		uiUtil.setupDevMode();
		$('#userRolesDiv').toggleClass('d-none');
	}

	await logAuthData();

	if (authError) {
		try {
			// If there's an auth error, let the auth.js page handle it (might be on purpose eg. for external user signup)
			await handleAuthStateError(startUrl.search(true));
		} catch (error) {
			if (/users must be invited to use this application/i.test(error)) { // this happens when we try to sign up an external user for the first time since we force create and existing cognito user first, since right now we're disallowing external signups
				console.error(`External user tried to sign up without being invited first: ${error}`);
				showAlert(false, 'User Signup Not Allowed', `Users must be invited to use this application. Please contact your project administrator for assistance. Forwarding to login page in 20 seconds.`, 60000);
			} else {
				console.error(`Auth error not handled by auth.js code, forwarding to login page: ${error}`);
				showAlert(false, 'Auth Error', `Unhandled auth error, forwarding to login page in 20 seconds: <br> ${error}`, 60000);
			}
			showNavMenuOnly();
			await asyncTimeout(20000);
			forwardToLoginPage();
		}

		return;
	}

	// Check to see if we're coming back from the hosted login through Google. This should be the only way to enter this if statement
	if (authCode) {
		if (await isUserSignedIn()) { // if the user is already signed in and we have the code/state above, remove it and keep going (will forward below)
			startUrl.removeSearch(['code', 'state']);
			history.replaceState(history.state, '', startUrl.valueOf());
		} else {
			try {
				await signUserInWithAuthCode(authCode);

				const redirect = getRedirectPageFromAuthState(authState);
				startUrl.removeSearch(['code', 'state']); // used, so lets get rid of these
				redirect && startUrl.addSearch('abvrState', redirect); // add it to our own state query so we can use it below
				history.replaceState(history.state, '', startUrl.valueOf());
			} catch (error) {
				console.error('Error logging user in. Forwarding to login page...', error);

				const redirect = getRedirectPageFromAuthState(authState);
				startUrl.removeSearch(['code', 'state']); // used, so lets get rid of these
				redirect && startUrl.addSearch('abvrState', redirect); // add it to our own state query so we can use it below
				history.replaceState(history.state, '', startUrl.valueOf());

				forwardToLoginPage();
				return;
			}
		}

		await logAuthData();
	}

	if (!await isUserSignedIn()) {
		console.error('User not logged in. Forwarding to login page...');
		forwardToLoginPage();
		return;
	} else {
		setFullstoryIdentity(curUser());

		navigateToRedirectedUrl({ fallbackUri: createSelectCompanyLink() });
		return;
	}
});

function showNavMenuOnly() {
	$('#mainContent').removeClass('d-none');
	$('#content').addClass('d-none');
	$('#spinner').remove();
}
