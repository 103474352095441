
import $ from 'jquery';



/*****************************************************/
/****************** ALERT FUNCTIONS ******************/
/*****************************************************/

export function showAlert(success, title, message, timeout = 2500, size = 'full', messageIsPreformatted = false) {
	const alertDiv = createAlertElement(success, title, message, size, messageIsPreformatted);
	getAlertDiv().append(alertDiv);
	setTimeout(() => alertDiv.alert('close'), timeout);
}

export function clearAlerts() {
	getAlertDiv().children().alert('close');
}

function createAlertElement(success, title, message, size = 'full', messageIsPreformatted = false) {
	const newAlertWrapper = $('<div>', { class: 'alert fade show mx-auto', role: 'alert' });
	newAlertWrapper.css('pointer-events', 'auto');
	success ? newAlertWrapper.addClass('alert-success') : newAlertWrapper.addClass('alert-danger');
	if (messageIsPreformatted) { message = `<pre>${message}</pre>`; }
	newAlertWrapper.html(`<strong class="mr-3">${title}</strong> ${message}`);
	switch (size) {
		case 'small':
		case 'sm':
			newAlertWrapper.prop('style', (i, origVal) => {
				origVal.setProperty('max-width', '500px');
				return origVal.cssText;
			});
			break;
		case 'med':
		case 'medium':
		case 'mid':
			newAlertWrapper.prop('style', (i, origVal) => {
				origVal.setProperty('max-width', '1000px');
				return origVal.cssText;
			});
			break;
		case 'big':
		case 'large':
		case 'lg':
			newAlertWrapper.prop('style', (i, origVal) => {
				origVal.setProperty('max-width', '1400px');
				return origVal.cssText;
			});
			break;
		case 'full':
		default:
		// let it go through without setting it, it'll go across the entire page
	}

	const dismissBtn = $('<button>', { type: 'button', class: 'close', 'data-dismiss': 'alert' }).html(`&times;`);
	newAlertWrapper.append(dismissBtn);
	return newAlertWrapper;
}

function getAlertDiv(nakedElement = false) {
	let el = $('#alertDiv');
	if (!el.length) {
		console.log('alertDiv element does not exist to insert alerts into. Creating now...');
		const newContainer = $('<div>', { class: 'container-fluid', style: 'margin-top:80px; margin-bottom:80px; pointer-events:none;' });
		$('body').append(newContainer);
		el = $('<div>', { id: 'alertDiv' });
		newContainer.append(el);
	}
	return nakedElement ? el[0] : el;
}